<template>
  <div class="w-96 px-6 pt-8 pb-4">
    <p class="font-grey-darker-100 text-base font-bold base-border-grey-100 border-b pb-4"> Tutorials </p>
    <div class="video-wrap flex flex-wrap">
      <div 
        v-for="(video, index) in videos"
        :key="video.id"
        class="video w-40 mt-6 cursor-pointer"
        :class="{ 'mr-4':  (index % 2) == 0}"
      >
      
      <a :href="video.link" target="_blank">
        <div class="relative">
          <img :src="require(`../../assets/images/video${video.id}.png`)" alt="">
          <img 
            :src="require(`../../assets/icons/play-btn.svg`)" 
            alt="" 
            class="absolute left-1/2 top-1/2 play-btn cursor-pointer"
          >
        </div>
        <div class="flex justify-between items-center mt-4">
          <p class="text-black text-sm leading-3 font-bold"> {{ video.title }} </p>
          <p class="base-bg-checkbox font-mono text-xs font-bold p-0.5 whitespace-nowrap rounded"> {{ video.duration }} </p>
        </div>
      </a>
        
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        videos: [
          // {
          //   id: 1,
          //   title: 'Interface Explanation',
          //   duration: '1 min',
          //   link: ""
          // },
          {
            id: 3,
            title: 'Exports & Data Visualization',
            duration: '6 min',
            link: "https://raditubecdn.s3.us-west-1.amazonaws.com/export_datavis_tutcompressed.mp4"
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.play-btn{
  transform: translateY(-50%) translateX(-50%);
}
</style>